import img1 from "../imgs/1.webp";
import img3 from "../imgs/2.webp";
import img4 from "../imgs/3.jpg";

import ysl1 from "../imgs/ysl-1.webp";
import ysl2 from "../imgs/ysl-2.webp";
import ysl4 from "../imgs/ysl-3.jpg";
import lv1 from "../imgs/lv-菜篮子-1.webp";
import lv2 from "../imgs/lv-菜篮子-2.webp";
import lv3 from "../imgs/lv-3.jpeg";

import goyard1 from "../imgs/goyard-1.webp";
import goyard2 from "../imgs/goyard-2.webp";
import goyard3 from "../imgs/goyard-harrdy-3.webp";

import gucci1 from "../imgs/gucci-1.webp";
import gucci2 from "../imgs/gucci-2.webp";
import gucci3 from "../imgs/gucci-tote-3.webp";

export const daBaos = {
    firstData: {
        title: '5\xa0只热门大包，你\xa0pick\xa0谁？',
    },
    otherData: {
        type: '4',
        list: [
            {
                left: {
                    imgs: [
                        {
                            src: img1,
                            width: 150,
                            style: { transform: "scaleX(-1)" },
                        },
                        {
                            src: img3,
                            width: 150,
                        }
                    ]
                },
                right: {
                    img: {
                        src: img4,
                        width: 150,
                    },
                    subTitle: {
                        brand: "Celine Anais",
                        price: "¥22,500",
                    },
                    advantage: [
                        "54*29.5*15cm，大容量设计，满足装载需求",
                        "双侧皮带可调节包口大小，可从54cm缩至30cm",
                        "黑色和棕色"
                    ]
                }
            },
            {
                left: {
                    imgs: [
                        {
                            src: ysl1,
                        },
                        {
                            src: ysl2,
                        }
                    ]
                },
                right: {
                    style: {
                        position: "relative",
                    },
                    img: {
                        src: ysl4,
                        width: 150,
                        height: 200,
                    },
                    subTitle: {
                        brand: "YSL 垃圾袋",
                        price: "¥22,900",
                        style: {
                            position: "absolute",
                            top: "40px",
                            left: "14px"
                        }
                    },
                    advantage: [
                        "瘦高版型，链条比22Bag细长，展现冷峻气场",
                        "小羊皮，YSL金属字母，独特做旧工艺"
                    ]
                }
            },
            {
                left: {
                    imgs: [
                        {
                            src: lv1,
                            width: 150,
                            height: 200,
                            // height: 200,
                        },
                        {
                            src: lv3,
                            width: 150,
                            height: 200,
                            // height: 200,
                        }
                    ]
                },
                right: {
                    top: {
                        style: {
                            backgroundColor: "#fff",
                        }
                    },
                    img: {
                        src: lv2,
                        width: 150,
                    },
                    subTitle: { 
                        brand: "LV 菜篮子",
                        price: "¥34,500",
                    },
                    advantage: [
                        "新出的矿物蓝色，吸引眼球",
                        "侧边扣子可调节，可变为托特包或菜篮子形状",
                    ]
                }
            },
            {
                left: {
                    imgs: [
                        {
                            src: goyard1,
                        },
                        {
                            src: goyard2,
                        }
                    ]
                },
                right: {
                    top: {
                        style: {
                            backgroundColor: "#fff",
                        }
                    },
                    img: {
                        src: goyard3,
                        width: 150,
                    },
                    subTitle: {
                        brand: "Goyard Hardy",
                        price: "$2,000",
                    },
                    advantage: [
                        "轻便、能装、防水，适合职场使用",
                        "即使装满物品，外观依然保持优雅",
                        "绿色，显眼且显品位",
                    ]
                }
            },
            {
                left: {
                    imgs: [
                        {
                            src: gucci1,
                            width: 150,
                            height: 200,
                        },
                        {
                            src: gucci2,
                            width: 150,
                            height: 200,
                        }
                    ]
                },
                right: {
                    top: {
                        style: {
                            backgroundColor: "#fff",
                        }
                    },
                    img: {
                        src: gucci3,
                        width: 150,
                    },
                    subTitle: {
                        brand: "Gucci Tote",
                        price: "¥18,000", 
                    },
                    advantage: [
                        "倪妮同款，松弛风格，老钱风的优雅",
                        "Gucci独特的老花纹路，复古大方",
                        "超大容量，适合日常和运动使用",
                    ]
                }
            }
        ]
    }
}