export const cropImage = (imgSrc, widthRate, heightRate) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = function () {
      console.log(img.height);
      console.log(img.width);
      const val = img.width / widthRate;
      const height = val * heightRate;
      let totalCrops = Math.ceil(img.height / height);

      console.log(totalCrops);
      //   return resolve([]);
      let crops = [];

      for (let i = 0; i < totalCrops; i++) {
        const canvas = document.createElement("canvas");
        canvas.width = img.width; // 或者您可能想要指定一个固定的宽度
        let cropHeight = height;

        if (i * height + cropHeight > img.height) {
          cropHeight = img.height - i * height;
        }

        canvas.height = cropHeight;

        const ctx = canvas.getContext("2d");
        const y = i * height;

        // 绘制图像的(0, 0)到(width, height)区域到画布的(0, 0)位置，且宽度和高度为width x height
        ctx.drawImage(
          img,
          0,
          y,
          canvas.width,
          canvas.height,
          0,
          0,
          canvas.width,
          canvas.height
        );

        crops.push(canvas.toDataURL("image/png"));
      }

      resolve(crops);
    };

    img.onerror = reject;
    img.src = imgSrc;
  });
};

export const isDivisible = (a, b) => {
  // 确保b不为0，因为除以0在数学上是未定义的
  if (b === 0) {
    return new Error();
    //   return false; // 或者抛出一个错误，这取决于你的需求
  }
  // 使用模运算符检查a是否能被b整除
  return a % b === 0;
};