import "./App.css";
import CutLongPic from "./pages/cut-long-pic";
import DaBaos from "./pages/da-baos";


function App() {

  return (
    <div className="App">
      <CutLongPic />
      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn Reacttest
        </a>
      </header> */}

      {/* <div className="firstImage">
        <FirstImage data={firstData} />
      </div>

      <div className="father">
        {list?.map((item, index) => <DownloadImage key={index} data={item}></DownloadImage>)}
      </div> */}
      <DaBaos />
    </div>
  );
}

export default App;
