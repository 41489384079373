import React, { } from "react";
import "./index.css";
import logo from "../../assets/imgs/logo.jpeg";

const FirstImage = React.memo(({ data }) => {
  const { title } = data;

  return (
    <div className="firstImageContainer">
     <img className="loggo" src={logo} />
     <div className="logoTitle">{title}</div>
    </div>
  );
});

export default FirstImage;
