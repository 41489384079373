import React, { useMemo, useState } from "react";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Flex, message, Upload } from "antd";

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const beforeUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt20M = file.size / 1024 / 1024 < 20;
  if (!isLt20M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt20M;
};

const UploadImage = React.memo((props) => {
  const { setImgUrl: setUrl } = props;
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState("");

  useMemo(() => {
    setUrl && setUrl(imageUrl);
  }, [setUrl, imageUrl]);

  const uploadButton = (
    <button style={{ border: 0, background: "none" }} type="button">
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );

  // 自定义上传函数
  const handleCustomRequest = async (info) => {
    console.log(info);
    setLoading(true);
    getBase64(info.file, (url) => {
      setLoading(false);
      setImageUrl(url);
    });
  };

  return (
    <Flex gap="middle" wrap>
      <Upload
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        beforeUpload={beforeUpload}
        customRequest={handleCustomRequest}
      >
        {imageUrl ? (
          <img src={imageUrl} alt="avatar" style={{ height: "100px" }} />
        ) : (
          uploadButton
        )}
      </Upload>
    </Flex>
  );
});

export default UploadImage;
