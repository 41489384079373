import "./index.scss";
import React from "react";
import { Image } from "antd";
// import img1 from "../../imgs/2.png";
// import img2 from "../../imgs/3.png";
import DownloadImage from "../../../../components/download-image";

const TwoImages = React.memo((props) => {
  const { img1, img2 } = props;

  return (
    <div className="picContainer">
      <DownloadImage>
        <div className="imgs">
          <Image className="itemImg" width={200} src={img1} alt="描述文本" />
          {Boolean(img2) && (
            <>
              <div className="divider" />
              <Image className="itemImg" width={200} src={img2} alt="" />
            </>
          )}
        </div>
      </DownloadImage>
    </div>
  );
});

export default TwoImages;
