import "./index.scss";
import { cropImage } from "../../../src/utils/index";
import React, { useState } from "react";
import { Button, Image } from "antd";
import imgUrl from "./imgs/1.jpeg";
import { CombinationType } from "./constants";
import TwoImages from "./components/two-images/index";
import { isDivisible } from "../../utils/index";
import UploadImage from "./components/upload-image";

const downloadImages = (images) => {
  let index = 0;
  const downloadImage = () => {
    if (index < images.length) {
      const imageBase64 = images[index];
      const link = document.createElement("a");
      link.download = `cropped_test_image_${index}.png`;
      link.href = imageBase64;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      index++;
      setTimeout(downloadImage, 1000); // 延迟1秒
    }
  };

  downloadImage();
};

const CutLongPic = React.memo(() => {
  const [imgs, setLimgs] = useState([]);
  const [combinationType, setCombinationType] = useState(CombinationType.One);
  const [imgUrl, setImgUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const onCropImage = async () => {
    console.log("start");
    setLoading(true);
    cropImage(imgUrl, 3, 8)
      ?.then((crops) => {
        console.log("end");
        // console.log(crops); // 这里会打印出所有裁剪后的图片的Base64字符串
        // 你可以根据需要进一步处理这些图片，比如将它们下载到本地或显示在网页上
        // downloadImages(crops);
        setLimgs(crops);
      })
      ?.finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      <UploadImage setImgUrl={setImgUrl} />
      {!imgs.length ? (
        <>
          {/* <Image width={200} src={imgUrl} alt="描述文本" /> */}
          <Button
            type="primary"
            loading={loading}
            disabled={!imgUrl}
            onClick={() => imgUrl && onCropImage()}
          >
            裁剪图片
          </Button>
        </>
      ) : (
        <>
          <Button
            type="primary"
            onClick={() => setCombinationType(CombinationType.One)}
          >
            单张
          </Button>
          <Button
            type="primary"
            onClick={() => setCombinationType(CombinationType.Two)}
          >
            两张
          </Button>
          <br />
          {combinationType}
          {console.log(combinationType === CombinationType.One)}
          {console.log(combinationType === CombinationType.Two)}
          {combinationType === CombinationType.One &&
            imgs?.map((item, index) => (
              <Image
                key={`one-${index}`}
                width={200}
                src={item}
                alt="描述文本"
              />
            ))}
          {combinationType === CombinationType.Two &&
            imgs?.map((item, index) => {
              if (!isDivisible(index, 2)) {
                return <></>;
              }
              return (
                <TwoImages
                  key={`two-${index}`}
                  img1={item}
                  img2={imgs[index + 1]}
                />
              );
            })}
        </>
      )}
    </div>
  );
});

export default CutLongPic;
