import { daBaos } from "./constants/index.js";
import React from "react";
import DownloadImage from "../../components/download-image/index.js";
import FirstImage from "../../components/first-img/index.js";
import SantuCom from "../../components/san-tu-com";
import "../../App.css";

const DaBaos = React.memo(() => {
  const { firstData, otherData } = daBaos;
  const { list } = otherData;

  return (
    <>
      <div className="firstImage">
        <DownloadImage>
          <FirstImage data={firstData} />
        </DownloadImage>
      </div>
      <div className="father">
        {list?.map((item, index) => (
          <DownloadImage key={index}>
            <SantuCom data={item} />
          </DownloadImage>
        ))}
      </div>
    </>
  );
});

export default DaBaos;
