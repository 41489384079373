import React from "react";
import "./index.css";

const SantuCom = React.memo(({ data }) => {
  const { left, right } = data;

  return (
    <div className="container">
      <div className="left">
        {left?.imgs?.map((img, idx) => (
          <img
            src={img.src}
            width={img.width}
            height={img.height}
            style={img.style}
          ></img>
        ))}
      </div>
      <div className="right" style={right?.style}>
        <div className="top" style={right?.top?.style}>
          <img
            src={right?.img?.src}
            width={right?.img?.width}
            height={right?.img?.height}
          ></img>
          <div style={right?.subTitle?.style}>
            <span>{right?.subTitle?.brand}</span>
            <br></br>
            <span>参考价：{right?.subTitle?.price}</span>
          </div>
          {/* <div className="tips">注：详细价格评论区见喔</div> */}
        </div>
        <div className="bottom">
          {right?.advantage?.map((text, idx) => (
            <div key={idx} className="text">
              {idx + 1}.&nbsp;&nbsp;{text}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
});

export default SantuCom;
