import React, { useRef } from "react";
import html2canvas from "html2canvas";
import "./index.css";
import { Button } from "antd"

const DownloadImage = React.memo(({ data: imgData, children }) => {
  const ref = useRef(null);

  const handleDownload = async () => {
    const element = ref.current;
    const canvas = await html2canvas(element, {
      scale: 4, 
    });
    const data = canvas
      .toDataURL("image/jpg")
      .replace("image/jpg", "image/octet-stream");

    const link = document.createElement("a");
    link.href = data;
    link.download = "image.jpg";
    link.click();
  };

  return (
    <div className="downloadContainer">
      <div ref={ref}>{children}</div>
      {/* <div ref={ref}><SantuCom data={imgData} /></div> */}
      {/* <button className="downloadBtn" onClick={handleDownload}></button> */}
      <Button className="downloadBtn" onClick={handleDownload} type="primary">下载图片</Button>
    </div>
  );
});

export default DownloadImage;
